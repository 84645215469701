import React from 'react'

export const AlternativeIconTelegram = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#DBE0E5" />
      <path
        d="M12.4934 16.6892L12.2618 19.9465C12.5931 19.9465 12.7366 19.8042 12.9087 19.6332L14.4621 18.1487L17.6809 20.5059C18.2713 20.8349 18.6872 20.6617 18.8464 19.9628L20.9593 10.0625L20.9599 10.0619C21.1471 9.18924 20.6443 8.84799 20.0691 9.06207L7.64994 13.8168C6.80235 14.1458 6.81519 14.6183 7.50585 14.8324L10.6809 15.82L18.056 11.2052C18.4031 10.9754 18.7187 11.1026 18.4591 11.3324L12.4934 16.6892Z"
        fill="#9EAAB6"
      />
    </svg>
  )
}
