import React from 'react'

export const AlternativeIconGithub = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#DBE0E5" />
      <path
        d="M15 8C11.1325 8 8 11.2139 8 15.1776C8 18.3495 10.0055 21.0393 12.7862 21.9876C13.1363 22.0552 13.2646 21.833 13.2646 21.6425C13.2646 21.4721 13.2587 21.0204 13.2558 20.4221C11.3087 20.8549 10.898 19.4591 10.898 19.4591C10.5795 18.6307 10.1192 18.4091 10.1192 18.4091C9.48517 17.9642 10.1682 17.9733 10.1682 17.9733C10.8712 18.0232 11.2404 18.7129 11.2404 18.7129C11.8646 19.8103 12.879 19.4932 13.2792 19.31C13.3422 18.8456 13.5224 18.5297 13.7225 18.3501C12.1679 18.1705 10.534 17.5533 10.534 14.8033C10.534 14.0199 10.8052 13.3796 11.2544 12.8774C11.1757 12.696 10.9394 11.9662 11.3157 10.9777C11.3157 10.9777 11.9019 10.7853 13.2407 11.7136C13.8007 11.5541 14.3957 11.475 14.9907 11.4713C15.5857 11.475 16.1807 11.5541 16.7407 11.7136C18.0707 10.7853 18.6569 10.9777 18.6569 10.9777C19.0332 11.9662 18.7969 12.696 18.7269 12.8774C19.1732 13.3796 19.4444 14.0199 19.4444 14.8033C19.4444 17.5606 17.8082 18.1675 16.2507 18.344C16.4957 18.5595 16.7232 18.9996 16.7232 19.6722C16.7232 20.6327 16.7144 21.4045 16.7144 21.6376C16.7144 21.8257 16.8369 22.0503 17.1957 21.9785C19.9962 21.0362 22 18.3446 22 15.1776C22 11.2139 18.8657 8 15 8Z"
        fill="#9EAAB6"
      />
    </svg>
  )
}
