import React from 'react'

export const AlternativeStatusIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 0C14.4477 0 14 0.447716 14 1V3C14 3.55228 14.4477 4 15 4C15.5523 4 16 3.55228 16 3H17C17.5523 3 18 2.55228 18 2C18 1.44772 17.5523 1 17 1H16C16 0.447716 15.5523 0 15 0Z"
      fill="#9EAAB6"
    />
    <path d="M0 2C0 1.44772 0.447693 1 1 1H12V3H1C0.447693 3 0 2.55228 0 2Z" fill="#9EAAB6" />
    <path
      d="M10 7C9.44769 7 9 7.44772 9 8V10C9 10.5523 9.44769 11 10 11C10.5523 11 11 10.5523 11 10H17C17.5523 10 18 9.55228 18 9C18 8.44772 17.5523 8 17 8H11C11 7.44772 10.5523 7 10 7Z"
      fill="#9EAAB6"
    />
    <path d="M0 9C0 8.44772 0.447693 8 1 8H7V10H1C0.447693 10 0 9.55228 0 9Z" fill="#9EAAB6" />
    <path
      d="M6 15C6 14.4477 5.55231 14 5 14C4.44769 14 4 14.4477 4 15V17C4 17.5523 4.44769 18 5 18C5.55231 18 6 17.5523 6 17H17C17.5523 17 18 16.5523 18 16C18 15.4477 17.5523 15 17 15H6Z"
      fill="#9EAAB6"
    />
    <path d="M1 15H2V17H1C0.447693 17 0 16.5523 0 16C0 15.4477 0.447693 15 1 15Z" fill="#9EAAB6" />
  </svg>
)
