import React from 'react'

export const AlternativeIconTwitter = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#DBE0E5" />
      <path
        d="M22 10.4206C21.4794 10.6615 20.9246 10.8212 20.3462 10.8988C20.9412 10.524 21.3954 9.93508 21.6089 9.22523C21.0541 9.57415 20.4416 9.82062 19.7889 9.95815C19.2621 9.36646 18.5114 9 17.6924 9C16.1034 9 14.8241 10.3606 14.8241 12.0286C14.8241 12.2686 14.8434 12.4994 14.8906 12.7191C12.5045 12.5963 10.3931 11.3898 8.97475 9.552C8.72712 10.0052 8.58188 10.524 8.58188 11.0825C8.58188 12.1311 9.09375 13.0606 9.85675 13.5988C9.39562 13.5895 8.94325 13.4483 8.56 13.2258C8.56 13.2351 8.56 13.2471 8.56 13.2591C8.56 14.7305 9.55487 15.9526 10.8595 16.2342C10.6259 16.3015 10.3712 16.3338 10.107 16.3338C9.92325 16.3338 9.73775 16.3228 9.56362 16.2822C9.9355 17.4812 10.9908 18.3628 12.2455 18.3914C11.269 19.1972 10.0291 19.6828 8.68688 19.6828C8.4515 19.6828 8.22575 19.6717 8 19.6412C9.27137 20.5062 10.7781 21 12.403 21C17.6845 21 20.572 16.3846 20.572 12.384C20.572 12.2502 20.5676 12.1209 20.5615 11.9926C21.1311 11.5662 21.6097 11.0335 22 10.4206Z"
        fill="#9EAAB6"
      />
    </svg>
  )
}
